import React, { FC } from "react";
import PrimaryLayout from "../components/layout/primary/primary";
import { graphql } from "gatsby";
import SEO from "../components/seo";
import ContentContainer from "components/container/content-container";
import EventsLanding from "components/blog/events-landing";

export const query = graphql`
  query {
    prismicEventLandingPage(prismicId: { eq: "ZSnBGREAACQAld2D" }) {
      data {
        body {
          ... on PrismicEventLandingPageDataBodyHappenings {
            id
            slice_type
            items {
              image {
                fluid(maxWidth: 400) {
                  ...GatsbyImgixFluid_noBase64
                }
                url
                alt
              }
              link {
                uid
                url
              }
            }
          }
          ... on PrismicEventLandingPageDataBodyHeadlines {
            id
            items {
              headline {
                raw
              }
              link {
                uid
                url
              }
              image {
                alt
                fluid(maxWidth: 1200) {
                  ...GatsbyImgixFluid_noBase64
                }
                url
              }
            }
            slice_type
          }
          ... on PrismicEventLandingPageDataBodyLanding {
            id
            primary {
              landing_image {
                fluid(maxWidth: 1200) {
                  ...GatsbyImgixFluid_noBase64
                }
                url
              }
            }
            slice_type
          }
          ... on PrismicEventLandingPageDataBodyText {
            id
            primary {
              text_block {
                raw
              }
            }
            slice_type
          }
          ... on PrismicEventLandingPageDataBodyVenueRentals {
            id
            slice_type
            items {
              image {
                alt
                url
                fluid(maxWidth: 400) {
                  ...GatsbyImgixFluid_noBase64
                }
              }
              link {
                uid
                url
              }
            }
          }
          ... on PrismicEventLandingPageDataBodyNewsSection {
            id
            items {
              image {
                fluid(maxWidth: 400) {
                  ...GatsbyImgixFluid_noBase64
                }
              }
              link_new_images {
                uid
                url
              }
              title_news {
                raw
              }
              subtitle {
                raw
              }
            }
            slice_type
          }
          ... on PrismicEventLandingPageDataBodyArticlesSection {
            id
            slice_type
            items {
              cta_text {
                raw
              }
              headline {
                raw
              }
              image {
                fluid(maxWidth: 1200) {
                  ...GatsbyImgixFluid_noBase64
                }
              }
              link {
                uid
                url
              }
              new_design
              position
              content {
                raw
              }
            }
          }
        }
        metadescription {
          raw
        }
        photo_slider {
          gallery_image {
            fluid(maxWidth: 680) {
              ...GatsbyImgixFluid_noBase64
            }
          }
        }
        slider
      }
    }
  }
`;

interface EventsPageProps {
  data: any;
}

const EventsPage: FC<EventsPageProps> = ({ data }) => {
  const content = data.prismicEventLandingPage.data.body;
  const description = data.prismicEventLandingPage.data.metadescription.raw[0];
  const gallery = data.prismicEventLandingPage.data.photo_slider;
  const slider = data.prismicEventLandingPage.data.slider;

  return (
    <PrimaryLayout>
      <SEO title="Events" description={description} />
      <ContentContainer width="100%">
        {/* EventsLanding component */}
        <EventsLanding
          content={content}
          slider={slider}
          gallery={gallery}
          title="Events and Venue Rentals"
        />
        {/* ColorDivider component */}
      </ContentContainer>
    </PrimaryLayout>
  );
};
export default EventsPage;
