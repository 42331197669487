import React, { FC } from "react";
import { Box, Text } from "theme-ui";
import styles from "./landing.style";
import LandingSliceBase from "components/landing-slice-base/landing-slice-base";
import PhotoSlider from "components/photo-slider/photo-slider";

type PropsType = {
  content: any;
  slider: boolean;
  gallery: any;
  title?: string;
};

const EventsLanding: FC<PropsType> = ({ content, slider, gallery, title }) => {
  return (
    <Box sx={styles.wrapper}>
      {slider && <PhotoSlider images={gallery} slice />}
      <Box sx={styles.events}>
        {content.length && (
          <LandingSliceBase content={content} events title={title} />
        )}
      </Box>
    </Box>
  );
};
export default EventsLanding;
